import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { toast } from '@/components/ui/use-toast';
import {
  notFound, useParentMatches, useRouteContext,
} from '@tanstack/react-router';
import useStaticData from '@/components/Hooks/useStaticData.js';

const errorToastCache = {};

const errorToast = (title, description) => {
  if (errorToastCache[title] && errorToastCache[title] > new Date().getTime() - 5000) {
    errorToastCache[title] = new Date().getTime();
    return;
  }

  errorToastCache[title] = new Date().getTime();
  toast({
    title,
    description,
    variant: 'destructive',
  });
};

const mutationFn = async (
  method = 'POST',
  middlewares = [],
  params = {},
  context = {},
  staticData = {},
) => {
  const newParams = middlewares.reduce(
    (acc, middleware) => middleware.request(acc),
    params,
  ) || {};

  const {
    endpoint, headers = {}, body = {}, json = true,
  } = newParams;

  const newHeaders = {
    ...((body instanceof FormData || !json) ? {} : { 'Content-Type': 'application/json', Accept: 'application/json' }),
    ...headers,
  };

  let newEndpoint = endpoint;
  if (method === 'GET') {
    const query = {
      ...queryString.parse(endpoint.split('?')[1] || ''),
      ...body,
    };

    newEndpoint = `${endpoint.split('?')[0]}?${queryString.stringify(query)}`;

    // if newEndpoint ends on ? then remove it
    if (newEndpoint.endsWith('?')) {
      newEndpoint = newEndpoint.slice(0, -1);
    }
  }

  const response = await fetch(newEndpoint, {
    method,
    headers: newHeaders,
    body: method === 'GET' ? undefined : (body instanceof FormData ? body : JSON.stringify(body)),
  });

  middlewares.forEach((middleware) => {
    middleware.response(response);
  });
  if (!response.ok) {
    const code = response.status;
    if (code >= 500 && code < 600) {
      errorToast('Interner Serverfehler', 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
    }

    if (code === 401) {
      await context?.auth?.logout(staticData?.needsAuth ?? true);
    }

    if (code === 404) {
      throw notFound();
    }

    if (json) {
      const data = await response.json();
      if (data.message) {
        throw new Error(data.message, {
          cause: {
            code,
            data,
          },
        });
      } else {
        throw new Error(response.statusText, {
          cause: {
            code,
            data,
          },
        });
      }
    }

    throw new Error(response.statusText, {
      cause: {
        code,
        data: response,
      },
    });
  }

  return json ? response.json() : response;
};

const useApiMutationWithoutEndpoint = (
  method = 'POST',
  middlewares = [],
  mutOpts = {},
  context = {},
  staticData = {},
) => useMutation({
  mutationFn: async (params = {}) => mutationFn(method, middlewares, { ...params }, context, staticData),
  ...mutOpts,
});

const useApiMutationWithEndpoint = (
  endpoint,
  method = 'POST',
  middlewares = [],
  mutOpts = {},
  context = {},
  staticData = {},
) => useMutation({
  mutationKey: [endpoint, method],
  mutationFn: async (params = {}) => mutationFn(method, middlewares, { ...params, endpoint }, context, staticData),
  ...mutOpts,
});

const useApiMutation = (
  method = 'POST',
  middlewares = [],
  mutOpts = {},
  endpoint = null,
) => {
  const context = useRouteContext({});
  const staticData = useStaticData();

  if (endpoint) {
    return useApiMutationWithEndpoint(endpoint, method, middlewares, mutOpts, context, staticData);
  }
  return useApiMutationWithoutEndpoint(method, middlewares, mutOpts, context, staticData);
};

export default useApiMutation;
export { useApiMutationWithoutEndpoint, useApiMutationWithEndpoint, mutationFn };
