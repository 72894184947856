import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

export default function AdminMenu({ className = '', children }) {
  return (
    <ul
      className={cn(
        'mx-auto mt-8 flex w-full flex-col py-4 text-white',
        className,
      )}
    >
      {children}
    </ul>
  );
}

AdminMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
