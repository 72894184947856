import React from 'react';
import {
  createFileRoute, Outlet, redirect,
} from '@tanstack/react-router';
import NavTopbar from '@/components/Navigation/NavTopbar.jsx';
import NavAdminSidebar from '@/components/Navigation/Admin/NavAdminSidebar.jsx';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import { useNavAdminSidebarVisibility } from '@/contexts/NavAdminSidebarVisiblityContext.jsx';
import Footer from '@/components/Home/Footer.jsx';
import {
  PlatformLayoutIndex as HomeRoute,
} from '@/routePaths.gen.js';

function Layout() {
  const { sidebarVisible, footerVisible } = useNavAdminSidebarVisibility();

  return (
    <div className="flex flex-row font-body">
      {sidebarVisible && <NavAdminSidebar className="hidden md:flex" />}
      <div className=" flex w-full min-w-0 flex-col">
        <div className="flex min-h-screen w-full min-w-0 flex-col pb-5">
          <NavTopbar inCustomerView={false} />
          <TransitionLayout className="container flex grow flex-col gap-20 px-10 py-5 font-body max-xl:px-4">
            <Outlet />
          </TransitionLayout>
        </div>
        {footerVisible && <Footer />}
      </div>
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export const Route = createFileRoute('/_admin-layout')({
  beforeLoad: async ({ context, location }) => {
    await context.user.ready().catch(() => {});

    if (!context.auth.isAuthenticated()) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.user.hasPermission('admin.portal')) {
      throw redirect({
        to: HomeRoute,
      });
    }

    return context.user.ready().catch(() => {
      context.auth.logout();
    });
  },
  component: () => <Layout />,
  staticData: {
    needsAuth: true,
  },
});
