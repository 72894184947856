import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

export default function AdminSubMenu({ className = '', children }) {
  return <ul className={cn('flex flex-col', className)}>{children}</ul>;
}

AdminSubMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
