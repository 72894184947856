import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import WelcomeBanner from '@/components/Home/WelcomeBanner.jsx';
import {
  LifebuoyIcon, SignalIcon,
  ChatBubbleLeftRightIcon, Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import {
  AdminLayoutAdminFaqHubCategory as CategoryHubRoute,
  AdminLayoutAdminFaqHubFaq as FaqHubRoute,
  AdminLayoutAdminOperationsHubAseReportingIndex as AseReportingHubRoute, AdminLayoutAdminOperationsHubPulseIndex,
} from '@/routePaths.gen.js';
import HubCard from '@/components/Admin/HubCard.jsx';
// eslint-disable-next-line import/no-unresolved
import HomeScreenBannerImage from '@/../assets/banners/home-screen-banner.jpg?format=webp';

function Index() {
  return (
    <React.Fragment>
      <WelcomeBanner imageUrl={HomeScreenBannerImage} hash="L9KK=_000000%K_1-:-o_NjDI9.8" />
      <h3 className="-mt-12 text-center text-2xl font-bold text-primary md:text-left">
        Was möchtest Du tun?
      </h3>
      <div className="-mt-8 flex flex-col gap-8 px-4 sm:flex-row">
        <HubCard
          title="ASE Monitoring"
          icon={LifebuoyIcon}
          className="w-full md:w-72"
          link={AseReportingHubRoute}
        />
        {/* <HubCard
          title="Content Manager"
          icon={FolderOpenIcon}
          className="w-full md:w-72"
          link={RoleHubRoute}
        /> */}
        <HubCard
          title="Pulse"
          icon={SignalIcon}
          className="w-full md:w-72"
          link={AdminLayoutAdminOperationsHubPulseIndex}
        />
        <HubCard
          title="FAQ Verwaltung"
          icon={ChatBubbleLeftRightIcon}
          className="w-full md:w-72"
          link={FaqHubRoute}
        />
        <HubCard
          title="Kategorie Verwaltung"
          icon={Square3Stack3DIcon}
          className="w-full md:w-72"
          link={CategoryHubRoute}
        />
      </div>
    </React.Fragment>
  );
}

export const Route = createFileRoute('/_admin-layout/admin/operations-hub/')({
  component: () => <Index />,
});
