import React, { useCallback } from 'react';
import { Link, useRouter } from '@tanstack/react-router';
import { cn } from '@/lib/utils';
import logo from '@/../assets/logos/novartis-logo-bw.svg';
import {
  InfoPageLayoutLegalDatenschutz as DataPrivacyRoute,
} from '@/routePaths.gen.js';

function CookieSettingsLink({ className }) {
  const router = useRouter();

  const handleCookieSettingsClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const originalLink = document.getElementById('custom-ot-sdk-btn');
    if (!originalLink) return;
    // eslint-disable-next-line no-script-url
    originalLink.href = 'javascript:void(0)';
    originalLink.click();
  }, [router]);

  return (
    <Link
      className={className}
      onClick={handleCookieSettingsClick}
    >
      Cookie Settings
    </Link>
  );
}

export function FooterLinks({ className }) {
  return (
    <React.Fragment>
      <Link
        id="imprint"
        className={cn('text-sm font-light text-white', className)}
        to="https://www.novartis.com/de-de/impressum"
        target="_blank"
      >
        Impressum
      </Link>
      <Link
        id="data-protection"
        className={cn('text-sm font-light text-white', className)}
        to={DataPrivacyRoute}
        target="_blank"
      >
        Datenschutzerklärung
      </Link>
      <Link
        id="terms-of-use"
        className={cn('text-sm font-light text-white', className)}
        to="https://www.novartis.com/de-de/nutzungsbedingungen"
        target="_blank"
      >
        Nutzungsbedingungen
      </Link>
      <CookieSettingsLink
        className={cn('text-sm font-light text-white', className)}
      />
    </React.Fragment>
  );
}

export default function Footer() {
  return (
    <div
      className="flex w-full flex-col-reverse items-center justify-between bg-primary px-16 text-center text-sm font-light text-white lg:flex-row lg:gap-5 lg:text-left"
    >
      <div className="flex h-full flex-col justify-between pb-6 lg:gap-4 lg:py-6">
        <div className="flex flex-col md:flex-row md:gap-5">
          <FooterLinks />
        </div>
        <div>
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          Novartis Pharma GmbH
        </div>
      </div>
      <img src={logo} alt="Novartis Logo" className="max-h-20 min-h-0 min-w-0 object-contain" />
    </div>
  );
}
