import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import WelcomeBanner from '@/components/Home/WelcomeBanner.jsx';
import {
  IdentificationIcon, SwatchIcon,
} from '@heroicons/react/24/outline';
import {
  AdminLayoutAdminUserHubUsers as UserHubRoute,
  AdminLayoutAdminUserHubRoles as RoleHubRoute,
} from '@/routePaths.gen.js';
import HubCard from '@/components/Admin/HubCard.jsx';
// eslint-disable-next-line import/no-unresolved
import HomeScreenBannerImage from '@/../assets/banners/home-screen-banner.jpg?format=webp';

function Index() {
  return (
    <React.Fragment>
      <WelcomeBanner imageUrl={HomeScreenBannerImage} hash="L9KK=_000000%K_1-:-o_NjDI9.8" />
      <h3 className="-mt-12 text-center text-2xl font-bold text-primary md:text-left">
        Was möchtest Du tun?
      </h3>
      <div className="-mt-8 flex flex-col gap-8 px-4 sm:flex-row">
        <HubCard
          title="User Verwaltung"
          icon={IdentificationIcon}
          className="w-full md:w-72"
          link={UserHubRoute}
        />
        <HubCard
          title="Rollen Verwaltung"
          icon={SwatchIcon}
          className="w-full md:w-72"
          link={RoleHubRoute}
        />
      </div>
    </React.Fragment>
  );
}

export const Route = createFileRoute('/_admin-layout/admin/user-hub/')({
  component: () => <Index />,
});
