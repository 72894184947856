export const R2faLayout = '';
export const AdminLayout = '';
export const InfoPageLayout = '';
export const LoginLayout = '';
export const PlatformLayout = '';
export const ReducedLayout = '';
export const LoginLayoutUserInvite = '/user-invite';
export const PlatformLayoutFeedback = '/feedback';
export const R2faLayoutTwoFactorAuthenticator = '/two-factor-authenticator';
export const LoginLayoutChangePassword = '/change-password';
export const LoginLayoutForgotPassword = '/forgot-password';
export const LoginLayoutLogin = '/login';
export const LoginLayoutOtp = '/otp';
export const PlatformLayoutMyProfile = '/my-profile';
export const PlatformLayoutIndex = '/';
export const InfoPageLayoutLegalDatenschutz = '/legal/datenschutz';
export const InfoPageLayoutLegalGrundsaetze = '/legal/grundsaetze';
export const PlatformLayoutHelpFaq = '/help/faq';
export const AdminLayoutAdminIndex = '/admin';
export const PlatformLayoutHelpIndex = '/help';
export const PlatformLayoutAdboardAdboardForum = '/adboard/$adboard/forum';
export const RecordingMeetingMeetingToken = '/recording/meeting/$meeting/$token';
export const AdminLayoutAdminAnalyticsHubUserInsights = '/admin/analytics-hub/user-insights';
export const AdminLayoutAdminFaqHubCategory = '/admin/faq-hub/category';
export const AdminLayoutAdminFaqHubFaq = '/admin/faq-hub/faq';
export const AdminLayoutAdminUserHubRoles = '/admin/user-hub/roles';
export const AdminLayoutAdminUserHubUsers = '/admin/user-hub/users';
export const AdminLayoutAdminAnalyticsHubIndex = '/admin/analytics-hub';
export const AdminLayoutAdminConferenceHubIndex = '/admin/conference-hub';
export const AdminLayoutAdminOperationsHubIndex = '/admin/operations-hub';
export const AdminLayoutAdminUserHubIndex = '/admin/user-hub';
export const PlatformLayoutAdboardAdboardIndex = '/adboard/$adboard';
export const AdminLayoutAdminAdboardsAdboardIdEdit = '/admin/adboards/$adboardId/edit';
export const AdminLayoutAdminAdboardsAdboardIdPreview = '/admin/adboards/$adboardId/preview';
export const AdminLayoutAdminAnalyticsHubAdboardInsightsArchive = '/admin/analytics-hub/adboard-insights/archive';
export const ReducedLayoutAdboardAdboardMeetingBreakout = '/adboard/$adboard/meeting/$breakout';
export const AdminLayoutAdminConferenceHubMyAdboardsArchive = '/admin/conference-hub/my-adboards/archive';
export const AdminLayoutAdminConferenceHubMyAdboardsDrafts = '/admin/conference-hub/my-adboards/drafts';
export const ReducedLayoutAdboardAdboardMeetingIndex = '/adboard/$adboard/meeting';
export const AdminLayoutAdminAnalyticsHubAdboardInsightsIndex = '/admin/analytics-hub/adboard-insights';
export const AdminLayoutAdminAnalyticsHubPlatformInsightsIndex = '/admin/analytics-hub/platform-insights';
export const AdminLayoutAdminConferenceHubMyAdboardsIndex = '/admin/conference-hub/my-adboards';
export const AdminLayoutAdminOperationsHubAseReportingIndex = '/admin/operations-hub/ase-reporting';
export const AdminLayoutAdminOperationsHubPulseIndex = '/admin/operations-hub/pulse';
export const AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndex = '/admin/analytics-hub/adboard-insights/$adboard';
export const ReducedLayoutAdboardAdboardForumMeetingMeetingIndex = '/adboard/$adboard/forum/meeting/$meeting';