import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function deepEqual(a, b) {
  if (a === b) {
    return true;
  }

  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i += 1) {
    const key = keysA[i];
    if (!b.hasOwnProperty(key)) {
      return false;
    }

    if (!deepEqual(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // If obj is not an object, return it as it is
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item));
  }

  // Create an empty object with the same prototype of obj
  const newObj = Object.create(Object.getPrototypeOf(obj));

  for (const key in obj) {
    // Check if the property belongs to the object itself and not its prototype chain
    if (obj.hasOwnProperty(key)) {
      // Recursively copy nested objects
      newObj[key] = deepCopy(obj[key]);
    }
  }

  return newObj;
}

export function deepMerge(target, source) {
  if (typeof target !== 'object' || typeof source !== 'object') {
    return source;
  }

  if (Array.isArray(target) && Array.isArray(source)) {
    return [...target, ...source];
  }

  const newTarget = deepCopy(target) || (Array.isArray(source) ? [] : {});

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        newTarget[key] = deepMerge(newTarget[key], source[key]);
      } else {
        newTarget[key] = source[key];
      }
    }
  }

  return newTarget;
}

export function getKeyValue(obj, key, defaultValue) {
  const parts = key.split('.');
  let value = obj || {};
  for (let i = 0; i < parts.length; i += 1) {
    if (value[parts[i]] === undefined) {
      return defaultValue;
    }
    if (i === parts.length - 1) {
      return value[parts[i]];
    }
    value = value[parts[i]] || {};
  }
  return value;
}

export function setKeyValue(obj, key, value) {
  const parts = key.split('.');
  const copy = deepCopy(obj);
  let current = copy;

  for (let i = 0; i < parts.length - 1; i += 1) {
    if (current[parts[i]] === undefined) {
      current[parts[i]] = {};
    }
    current = current[parts[i]];
  }
  current[parts[parts.length - 1]] = value;

  return copy;
}
