import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { AdminMenuItemProvider } from '@/contexts/AdminMenuItemContext.jsx';
import useRoutePath from '@/components/Hooks/useRoutePath';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';

export default function AdminMenuItemGroup({
  className = '',
  icon = null,
  title,
  route,
  children,
  setActive,
  active = false,
}) {
  const routeMatch = useMatchRoute();
  const navigate = useNavigate();

  useEffect(() => {
    if (routeMatch({
      to: route,
      fuzzy: true,
    })) {
      setActive(title);
    }
  }, [route, routeMatch]);

  const handleOnClick = useCallback(() => {
    navigate({ to: route });
    setActive(title);
  }, [route, setActive, title, navigate]);

  const Icon = icon;
  return (
    <AdminMenuItemProvider title={title}>
      <button
        type="button"
        onClick={handleOnClick}
        className={cn(
          'flex items-center px-6 py-2',
          active && 'bg-white/40',
          className,
        )}
      >
        <Icon className="flex size-8 stroke-2" />
        <span className="ml-4 flex text-[18px] font-bold">{title}</span>
        {active && (
          <ChevronDownIcon className="ml-auto flex size-6 justify-end stroke-2" />
        )}
        {!active && (
          <ChevronRightIcon className="ml-auto flex size-6 justify-end stroke-2" />
        )}
      </button>
      {active && children}
    </AdminMenuItemProvider>
  );
}

AdminMenuItemGroup.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  setActive: PropTypes.func.isRequired,
  active: PropTypes.bool,
};
