import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

export default function AdminMenuItem({ className = '', children }) {
  return <li className={cn('flex flex-col', className)}>{children}</li>;
}

AdminMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
