import React, {
  createContext, useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouterHolder } from '@/contexts/RouterHolderContext.jsx';
import { useQueryClient } from '@tanstack/react-query';
import {
  LoginLayoutLogin as LoginRoute,
  R2faLayoutTwoFactorAuthenticator as TwofactAuthRoute,
} from '@/routePaths.gen.js';
import { mutationFn } from '@/components/Hooks/useApiMutation.js';
import AuthMiddleware from '@/Middlewares/AuthMiddleware.js';

export const AuthContext = createContext();
const AUTH_TOKEN_KEY = 'token';
export function AuthProvider({ children }) {
  const [token, setTokenInternal] = useState(
    localStorage.getItem(AUTH_TOKEN_KEY),
  );
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shouldRedirectTwoFactAuth, setShouldRedirectTwoFactAuth] = useState(false);

  const { navigate } = useRouterHolder();
  const queryClient = useQueryClient();

  // TODO: We only check if the token is set, but not if it is valid.
  const isAuthenticated = useCallback(() => !!token, [token]);

  const logout = useCallback(async (needsAuth) => {
    if (!needsAuth && !isAuthenticated()) {
      return;
    }
    await mutationFn('POST', [AuthMiddleware], { endpoint: '/api/auth/logout' }, { auth: { logout: () => {} }, user: {} });
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setTokenInternal(null);
    setShouldRedirect(true);
    queryClient.clear();
    await queryClient.invalidateQueries();
  }, [queryClient, isAuthenticated]);

  useEffect(() => {
    if (token !== null) return;
    if (!shouldRedirect) return;
    setShouldRedirect(false);

    const redirect = async () => {
      const search = new URLSearchParams(window.location.search);
      await navigate({
        to: LoginRoute,
        search: {
          redirect: window.location.pathname,
          ...window.location.search ? Object.fromEntries(search) : {},
        },
      });
      queryClient.clear();
      await queryClient.invalidateQueries();
    };
    redirect();
  }, [token, shouldRedirect, navigate, queryClient]);

  useEffect(() => {
    if (!shouldRedirectTwoFactAuth) return;
    setShouldRedirectTwoFactAuth(false);

    const redirect = async () => {
      const search = new URLSearchParams(window.location.search);
      await navigate({
        to: TwofactAuthRoute,
        search: {
          redirect: window.location.pathname,
          ...window.location.search ? Object.fromEntries(search) : {},
        },
      });
    };
    redirect();
  }, [shouldRedirectTwoFactAuth, queryClient, navigate]);

  const setToken = useCallback((newToken) => {
    if (!newToken) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      setTokenInternal(null);
      return;
    }

    localStorage.setItem(AUTH_TOKEN_KEY, newToken);
    setTokenInternal(newToken);
  }, []);

  const twoFactorAuthentication = useCallback(async () => {
    setShouldRedirectTwoFactAuth(true);
  }, []);

  const values = useMemo(
    () => ({
      token,
      isAuthenticated,
      setToken,
      logout,
      twoFactorAuthentication,
    }),
    [token, isAuthenticated, setToken, logout, twoFactorAuthentication],
  );

  return (
    <AuthContext.Provider
      value={values}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
