import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

export default function TransitionLayout({ children, className = '' }) {
  return (
    <motion.div
      className={className}
      initial="initialState"
      animate="animateState"
      exit="exitState"
      transition={{
        type: 'tween',
        duration: 0.5,
      }}
      variants={{
        initialState: {
          opacity: 0,
        },
        animateState: {
          opacity: 1,
        },
        exitState: {
          opacity: 0,
        },
      }}
    >
      {children}
    </motion.div>
  );
}

TransitionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
